.user-list {
  width: 100%;
  height: 100%;
  padding: 36px 28px;
  box-sizing: border-box;
  background: #FFF;
  border-radius: 4px;

  &__topbar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 28px;
    border-bottom: 1px solid #DCDEE0;

    &--right {
      display: flex;
      gap: 24px;
    }

    &--btn {
      display: flex;
      width: 120px;
      height: 36px;
      align-items: center;
      justify-content: center;
      color: #565656;
      border: 1px solid #E3E3E3;
      border-radius: 4px;
      cursor: pointer;

      &.add {
        background: #3F6AE2;
        color: #FFF;
      }
    }
  }

  &__search-bar {
    display: flex;
    padding-top: 28px;
    box-sizing: border-box;
    margin-bottom: 36px;

    &--cols {
      display: flex;
      gap: 12px;
    }

    &--column {
      width: 220px !important;
    }

    &--btn {
      display: flex;
      width: 120px;
      background: #3F6AE2;
      color: #FFF;
      justify-content: center;
      align-items: center;
      margin-left: 18px;
      border-radius: 4px;
      cursor: pointer;

      &.secondary {
        background: #FFF;
        border: 1px solid #777777;
        color: #777777;
      }
    }
  }

  &__content {
    width: 100%;
  }
}
