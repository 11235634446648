.cfs-loading {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #3F6AE2;
  font-weight: bolder;
  font-size: 128px;
}
