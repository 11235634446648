@import '~antd/dist/antd.css';

html {
  background-color: #F1F1F1;
}

body {
  background-color: #F1F1F1;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}
