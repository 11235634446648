.housebl-form {
  &__block {
    &--label {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 18px;

      &:before {
        content: '';
        display: inline;
        border-left: 4px solid #3F6AE2;
        margin-right: 8px;
      }
    }

    &--content {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      column-gap: 24px;
    }

    &--content-item {
      width: 210px;
    }
  }
}
