.login {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #FFF;

  &__main {
    width: 420px;
  }

  &__logo {
    width: 420px;
  }

  &__center-text {
    margin: 68px auto;
    text-align: center;

    &--main {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #5B5B5B;
      margin-bottom: 48px;
    }

    &--secondary {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__form {
    width: 100%;
    margin-top: 48px;

    &--label {
      font-size: 24px;
      font-weight: 500;
      color: #5B5B5B;
      margin-bottom: 20px;
    }

    &--input {
      margin-bottom: 16px !important;
      border-radius: 4px;
    }

    &--btn {
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: center;
      align-items: center;
      color: #FFF;
      font-weight: 500;
      background: #2C6CF4;
      border-radius: 4px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    &--link {
      display: flex;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      color: #5B5B5B;
      justify-content: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
