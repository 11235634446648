@sidebar-width: 200px;
@topbar-height: 48px;

.cfs-panel {
  display:flex;
  width: 100%;
  height: 100%;

  &__sidebar {
    width: @sidebar-width;
    height: 100%;
    background: #FFF;
    box-shadow: 4px 0px 10px 0px #0000000D;
    flex-shrink: 0;

    &--logo {
      display: flex;
      width: 100%;
      height: 86px;
      justify-content: center;
      align-items: center;
    }

    &--menu {
      color: #7B7B7B;
      font-size: 16px;
      font-weight: 500;
    }

    &--primary-menu {
      display: block;
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 0 0 0 24px;
      box-sizing: border-box;
      color: #919191;
      cursor: pointer;

      &.current {
        color: #228AFF;
        background: #E1E1E1;
        border-right: 2px solid #1890FF;
      }
    }

    &--secondary-menu {
      display: block;
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #919191;
      cursor: pointer;

      &.current {
        color: #228AFF;
        background: #F6F6F6;
      }
    }
  }

  &__body {
    width: 100%;
  }

  &__body-topbar {
    display: flex;
    width: 100%;
    height: @topbar-height;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px #00000014;
    justify-content: space-between;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 400;

    &--left {
      display: flex;
      align-items: center;
    }

    &--right {
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  &__body-main {
    width: 100%;
    height: calc(100% - @topbar-height);
    box-sizing: border-box;

    &--content {
      width: 100%;
      height: 100%;
      padding: 24px;
      overflow-y: auto;
      box-sizing: border-box;
    }
  }
}
